/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(".arrow-prev a").hide();
        $(".arrow-prev .grey-out").show();
        $(".arrow-next .grey-out").hide();

        // JavaScript to be fired on all pages
        //watches for keyup 
        $("#searchinput").keyup(function(event){
          var searchValue = $("#searchinput").val();
          //sets page num for one
          $("#page_num").val(1);

          //if they type more than 3 its triggered and if they type out and change their mind
          if (searchValue.length >= 3 || searchValue.length === 0){
            mySearch();
          }
        });

        $("#search-btn").on('submit click', function(event){
          if(event.keyCode == 13) {
            event.preventDefault();
            return false;
          } else {
           event.preventDefault(); 
          }
        });

        $("#searchinput").on('keypress', function(event){
          if(event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        });



        $(".arrow-next a").click(function(event){
          event.preventDefault();
          $(".arrow-prev a").show();
          $(".arrow-prev .grey-out").hide();
          //next updates page num attr to the correct page, preps for ajax
          $("#page_num").val(parseInt($("#page_num").val())+1);
          mySearch();
        });

        $(".arrow-prev a").click(function(event){
          event.preventDefault();

          //next updates page num attr to the correct page, preps for ajax
          //so back a page
          $("#page_num").val(parseInt($("#page_num").val())-1);
          var checkReset = parseInt($("#page_num").val());
          console.log(checkReset);
          if ( checkReset === 0 || checkReset === 1){
            $(".arrow-prev a").hide();
            $(".arrow-prev .grey-out").show();
          }
          mySearch();
        });


        function mySearch(){
          //grabs the search input
          var searchValue = $("#searchinput").val();
          //gets a page num (from next and prev)
          var pageValue = $("#page_num").val();
          //url for php file 
          var url = $("#searchinput").parent().attr('action');
          $.ajax({
            url: url,
            method: "POST",
            //send page value and value to match variables in ajax_search.php
            data: { query: searchValue, page: pageValue },
            complete: function(response){
              // console.log(response);
              //if the type is undefined-> needs else
              if (typeof response != "undefined"){
                //if length is greater than 0, NEEDS ELSE
                if (response.responseJSON.items.length > 0){
                  $(".list").html("");
                  //empty list
                  // console.log(response.responseJSON);
                  var totalPosts = response.responseJSON.found_posts;
                  var pageTotal = $("#page_num").val() * 6;
                  var nextPage = pageTotal < totalPosts;
                  if (nextPage){
                    $(".arrow-next a").show();
                    $(".arrow-next .grey-out").hide();
                  } else {
                    $(".arrow-next a").hide();
                    $(".arrow-next .grey-out").show();
                  }
                  for(var i = 0; i < response.responseJSON.items.length; i++){
                    var item = response.responseJSON.items[i];
                    $(".list").append('<div class="item"><p class="publication">'+ item.article_title +'</p><p class="date">'+ item.article_date +'</p><a href="'+ item.article_url +'">'+ item.article_display_url +'</a></div>');
                  }
                }
              }
            }
          });
        }   
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
